import React, { useRef, useState } from 'react'
import { constants, useTranslation, useServices } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import NBCICanadaInvoiceKeys from '../../constants/locale/key/NBCICanadaInvoice'
import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import Table from '../../components/aciacehighway/Table'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import pathMap from '../../paths/pathMap'
import { getStatusMetadata } from '../../common/NACommon'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'
import { Chip, Typography, useTheme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import _ from 'lodash'
import { FileForUserGetPartyId } from 'src/common/FileForUserCommon'
import sweetalert from 'sweetalert'
import { format } from 'date-fns'

const {
  filter: { BETWEEN, LIKE , EQUAL, IN}
} = constants

function TablePage(props) {
  const { history, showNotification } = props
  const tableRef = useRef(null)
  const [alertDialog, setAlertDialog] = useState({ open: false, invoice: null })
  const theme = useTheme()
  const { createRecord, deleteRecord, securedSendRequest } = useServices()
  const { translate } = useTranslation(Namespace.N_B_C_I_CANADA_INVOICE)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    const invoiceNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_NUMBER
    )
    const invoiceDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.INVOICE_DATE
    )
    const entryNumber = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.ENTRY_NUMBER
    )
    const carrierCode = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CARRIER_CODE
    )
    const carrierName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CARRIER_NAME
    )
    const ccn = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CCN
    )
    const editSentRecords = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.EDIT_SENT_RECORDS
    )
    const editButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.EDIT_BUTTON
    )
    const cloneButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CLONE_BUTTON
    )
    const submitButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SUBMIT_BUTTON
    )
    const deleteButton = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.DELETE_BUTTON
    )
    const submitSentRecord = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SUBMIT_SENT_RECORD
    )
    const submitSuccessMsg = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SUBMIT_SUCCESS_MSG
    )
    const submitErrorMsg = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.SUBMIT_ERROR_MSG
    )
    const status = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.STATUS
    )
    const createdDate = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.CREATED_DATE
    )
    const templateName = translate(
      Namespace.N_B_C_I_CANADA_INVOICE,
      NBCICanadaInvoiceKeys.TEMPLATE_NAME
    )
    return {
      carrierCode,
      status,
      invoiceNumber,
      ccn,
      invoiceDate,
      carrierName,
      entryNumber,
      createdDate,
      submitSuccessMsg,
      submitErrorMsg,
      editSentRecords,
      editButton,
      cloneButton,
      submitButton,
      submitSentRecord,
      deleteButton,
      templateName
    }
  }

  const columns = [
    {
      field: 'invoiceNumber',
      title: translatedTextsObject.invoiceNumber
    },
    {
      field: 'ccn',
      title: translatedTextsObject.ccn
    },
    {
      field: 'invoiceDate',
      title: translatedTextsObject.invoiceDate,
      render: (rowData) =>
        rowData.invoiceDate && moment(rowData.invoiceDate).format('D MMM YYYY')
    },
    {
      field: 'carrierName',
      title: [
        translatedTextsObject.carrierName,
        translatedTextsObject.carrierCode
      ].join(' / '),
      render: (rowData) => {
        return (
          <>
            <Typography variant='inherit'>
              {rowData.carrierName}              
            </Typography>
            {rowData.carrierCode && (
              <Typography component='div' variant='caption'>
                {rowData.carrierCode}              
              </Typography>
            )}
          </>
        )
      }
    },
    {
      field: 'entryNumber',
      title: translatedTextsObject.entryNumber
    },
    {
      field: 'createdDate',
      title: translatedTextsObject.createdDate,
      render: (rowData) =>
        rowData.createdDate && moment(rowData.createdDate).format('D MMM YYYY')
    },
    {
      field: 'templateName',
      title: translatedTextsObject.templateName
    },
    {
      field: 'status',
      title: translatedTextsObject.status,
      render: (rowData) => {
        if (!rowData.status) return

        const status = getStatusMetadata(rowData.status)

        return (
          <Chip
            label={status.label}
            size='small'
            style={{
              color: status.contrastColor,
              backgroundColor: status.color,
              fontSize: 12
            }}
          />
        )
      }
    }
  ]

  const filters = [
    {
      label: translatedTextsObject.invoiceNumber,
      type: 'textfield',
      name: 'invoiceNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.ccn,
      type: 'textfield',
      name: 'ccn',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.invoiceDate,
      type: 'daterange',
      name: 'invoiceDate',
      operatorType: BETWEEN
    },
    {
      label: translatedTextsObject.carrierName,
      type: 'textfield',
      name: 'carrierName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.entryNumber,
      type: 'textfield',
      name: 'entryNumber',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.templateName,
      type: 'textfield',
      name: 'templateName',
      operatorType: LIKE
    },
    {
      label: translatedTextsObject.status,
      type: 'checkbox',
      name: 'status',
      operatorType: IN,
      options: [
        {
          fullWidth: true,
          label: 'Draft',
          value: 'draft',
          filterValue: {
            value: '1005'
          }
        },
        {
          fullWidth: true,
          label: 'Accepted',
          value: 'accepted',
          filterValue: {
            value: '1000'
          }
        },
        {
          fullWidth: true,
          label: 'Sent',
          value: 'sent',
          filterValue: {
            value: '1008'
          }
        },
        {
          fullWidth: true,
          label: 'Rejected',
          value: 'rejected',
          filterValue: {
            value: '1001'
          }
        },
        {
          fullWidth: true,
          label: 'Template',
          value: 'template',
          filterValue: {
            value: '1002'
          }
        }
      ]
    },
    {
      label: translatedTextsObject.createdDate,
      type: 'daterange',
      name: 'createdDate',
      operatorType: BETWEEN
    }
  ]

  function handleCloneInvoice(rowData) {
    createRecord.execute(
      NBCICanadaInvoiceApiUrls.CLONE,
      rowData,
      (data) => {
        const partyData = data.invTradeParty.map(
          (invTradeParty, index) => ({ ...invTradeParty, _id: index + 1 })
        )
        data.invTradeParty = partyData
      
        history.push(pathMap.N_B_C_I_CANADA_INVOICE_ADD_VIEW, data)}
    )
  }

  function handleDeleteInvoice() {
    if (alertDialog.invoice) {
      deleteRecord.execute(
        NBCICanadaInvoiceApiUrls.DELETE,
        alertDialog.invoice,
        () => {
          showNotification('success', 'Invoice deleted successfully')
          setAlertDialog({ open: false, invoice: null })

          if (tableRef.current?.performRefresh) {
            tableRef.current.performRefresh()
          }
        }
      )
    }
  }

  function handleSubmitInvoices(invoices) {
    let result = [...invoices]

    const fileForUserDetails = FileForUserGetUserDetails()

    if (fileForUserDetails) {
      result.forEach((invoice) => {
        invoice['fileForUserId'] = fileForUserDetails.fileForUserId
        invoice['fileForUserPartyId'] = fileForUserDetails.fileForUserPartyId
        invoice['fileForUserLoginId'] = fileForUserDetails.fileForUserLoginId
      })
    }

    if (tableRef.current?.setLoading) {
      tableRef.current.setLoading(true)
    }

    securedSendRequest.execute(
      'POST',
      NBCICanadaInvoiceApiUrls.SUBMIT_ALL,
      result,
      (response) => {
        if (response.status == 200) {
          const { errorMessages } = response.data
          if (errorMessages && errorMessages.length > 0) {
            showNotification('error', errorMessages)
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMsg)

            if (tableRef.current?.performRefresh) {
              tableRef.current.performRefresh()
            }
          }
        }
      },
      () => showNotification('error', translatedTextsObject.submitErrorMsg),
      () => {
        if (tableRef.current?.setLoading) {
          tableRef.current.setLoading(false)
        }
      }
    )
  }

  async function exportCAInvoice(data) {
    if (data.length > 0) {
      const config = { responseType: 'blob' }

      securedSendRequest.execute('POST', NBCICanadaInvoiceApiUrls.EXPORT_CSV, data,
        (response) => {
          let blob = new Blob([response.data])

          let first = 'NBCI_CA_INVOICE_EXPORT_'
          let curDate = format(new Date(), 'yyyyMMddHHmmss')
          let filename = first + curDate + '.csv'
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.href = url
          a.download = filename
          a.click()
        },
        (error) => console.error(error),
        undefined,
        config,
        null
      )
    }
  }

  return (
    <>
      <Table
        actions={[
          {
            buttonProps: {
              color: 'primary',
              size: 'medium',
              startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
              onClick: () =>
                history.push(pathMap.N_B_C_I_CANADA_INVOICE_ADD_VIEW)
            },
            label: 'Create invoice'
          }
        ]}
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: ['fal', 'pen'],
            onClick: (rowData) =>
              history.push(
                `${pathMap.N_B_C_I_CANADA_INVOICE}/edit/${rowData.id}`
              ),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.editSentRecords
                : translatedTextsObject.editButton
          }
        ]}
        // exportData={{ url: NBCICanadaInvoiceApiUrls.EXPORT }}
        fetch={{ url: NBCICanadaInvoiceApiUrls.GET }}
        fetchFilters={[
          { field: 'partyId', operator: EQUAL, value: FileForUserGetPartyId() }
        ]}
        filters={filters}
        onRowClick={(rowData) =>
          history.push(`${pathMap.N_B_C_I_CANADA_INVOICE}/view/${rowData.id}`)
        }
        rowActions={[
          {
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneInvoice
          },
          {
            disabled: (rowData) => rowData.status !== '1005' && rowData.status !== '1002',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) =>
              setAlertDialog({ open: true, invoice: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005' && rowData.status !== '1002'
                ? "Cannot delete invoice with status other than 'Draft' or 'Template'"
                : null
          }
        ]}
        showNotification={showNotification}
        sortConfig={{
          type: 'column',
          defaultField: 'createdDate',
          defaultDirection: 'DESC'
        }}
        persistSettings
        tableRef={tableRef}
        checkboxSelection
        selectActions={[
          {
            disabled: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === '1008') {
                  shouldDisable = true
                }
              })

              return shouldDisable
            },
            icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
            label: translatedTextsObject.submitButton,
            onClick: handleSubmitInvoices,
            tooltip: (rows) => {
              let shouldDisable = false

              rows.forEach((rowData) => {
                if (rowData.status === '1008') {
                  shouldDisable = true
                }
              })

              return shouldDisable
                ? translatedTextsObject.submitSentRecord
                : null
            }
          },
          {
            icon: <FontAwesomeIcon icon={['fal', 'link']} />,
            label: "Export CA Invoice(s)",
            onClick: (rows) => {
              sweetalert("Exporting CA Invoice in the background. The report will be downloaded automatically once ready.", "", "success");
              return exportCAInvoice(rows);
            }
          }
        ]}
      />
      <AlertDialog
        cancelLabel='No, take me back'
        confirmLabel='Yes, delete'
        open={alertDialog.open}
        onClose={() => setAlertDialog({ open: false, invoice: null })}
        onCancel={() => setAlertDialog({ open: false, invoice: null })}
        onConfirm={handleDeleteInvoice}
        title={translatedTextsObject.deleteButton}
      >
        Items that you delete can't be restored. Are you sure about this?
      </AlertDialog>
    </>
  )
}

export default TablePage
