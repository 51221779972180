import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  const dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )
  const regexAlphaNumeric =
    '^[a-zA-Z0-9 ,+\\-$#@%&()!\'`^_:;.?\\[\\]\\{\\}\\/="\\|\\\\ ]+$'
  const errMsgAlphaNumeric =
    'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!`^_:;.?()[]{}\'"'
  const regexCharacters = '^[a-zA-Z]+'
  const errMsgCharacters = 'Invalid Value - Allowed Alphabets'
  const regexAlphaNum = '^[a-zA-Z0-9]*$'
  const errMsgAlphaNum = 'Invalid Value - Allowed Alphanumeric'
  const regexAlphaNum1 = '^[a-zA-Z0-9-., ]*$'
  const errMsgAlphaNum1 = 'Invalid Value - Allowed Alphanumeric, space, . - ,'
   const regexAlphaNum2 = '^[a-zA-Z0-9-., _]*$'
  const errMsgAlphaNum2 = 'Invalid Value - Allowed Alphanumeric, space, . - , _'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    invoiceNumber: Yup.string().when('status', {
      is: (status) => (status === '1002'),
      then:  validateStringField (
        22,
        regexAlphaNum2,
        errMsgAlphaNum2
      ).required(requiredMessage),
      otherwise: validateStringField (
        22,
        regexAlphaNum1,
        errMsgAlphaNum1
      ).required(requiredMessage)
    }),
    invoiceNumber2: validateStringField(22, regexAlphaNum1, errMsgAlphaNum1),
    /*upsScsClientId: validateStringField(
      15,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),*/
    probillNo: validateStringField(35, regexAlphaNumeric, errMsgAlphaNumeric),
    invoiceDate: Yup.date()
      .validFormat()
      .typeError(dateTypeErrorMessage)
      .required(requiredMessage),
    purchaseOrderNum: validateStringField(22, regexAlphaNum1, errMsgAlphaNum1),
    currencey: validateStringField(3, regexCharacters, errMsgCharacters).min(
      2,
      'Field can contain minimum of 2 characters'
    ),
    tripVoyageNo: validateStringField(25, regexAlphaNum, errMsgAlphaNum),
    shipmentDate: Yup.date()
      .validFormat()
      .typeError(dateTypeErrorMessage)
      .required(requiredMessage),
    entryNumber: validateStringField(50, regexAlphaNum, errMsgAlphaNum),
    transactionType: Yup.string().nullable(),
    portOfExit: validateStringField(4, regexAlphaNum, errMsgAlphaNum),
    transportMode: Yup.string().nullable().required(requiredMessage),
    carrierCode: validateStringField(4, regexAlphaNumeric, errMsgAlphaNumeric).test("carrierCode",requiredMessage,function () {
      let carrierCode = this.parent["carrierCode"];
      let carrierName = this.parent["carrierName"];
      if(undefined === carrierCode && undefined === carrierName){
        return false;
      }else{
        return true;
      }
    }),
    carrierName: validateStringField(35, regexAlphaNumeric, errMsgAlphaNumeric).when(['carrierCode'],{
      is: (carrierCode) => (!carrierCode ),
      then: Yup.string().required(requiredMessage)
    }),
    ccn: validateStringField(25, regexAlphaNum, errMsgAlphaNum),
    billOfLading: validateStringField(
      25,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    grossWeight: validateStringField(12, regexAlphaNumeric, errMsgAlphaNumeric),
    grossWeightUom: Yup.string().nullable(),
    netWeight: validateStringField(12, regexAlphaNumeric, errMsgAlphaNumeric),
    netWeightUom: Yup.string().nullable(),
    marksNumbers: validateStringField(
      80,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    status: Yup.string().nullable(),
    submissionType: validateStringField(15, regexCharacters, errMsgCharacters),
    remainderFlag: validateStringField(1, regexCharacters, errMsgCharacters),
    remainderNote: validateStringField(
      100,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ),
    naftaForm: Yup.array(),
    bolForm: Yup.array(),
    invSummary: Yup.array(),
    shipmentSummary: Yup.array(),
    packingList: Yup.array(),
    b13aForm: Yup.array(),
    invDetails: Yup.array(),
    invTradeParty: Yup.array(),
    ciForm: Yup.array(),
    cfiaForm: Yup.array(),
    importDeclForm: Yup.array()
  })
}

export default makeValidationSchema
