import { Box, Card } from '@material-ui/core'
import React, { useState, useRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useServices } from 'cng-web-lib'
import pathMap from '../../paths/pathMap'
import NBCICanadaInvoiceApiUrls from '../../apiUrls/NBCICanadaInvoiceApiUrls'
import withFormState from '../../components/aciacehighway/hocs/withFormState'
import Stepper from '../../components/aciacehighway/Stepper'
import InvoiceEditForm from '../../views/nbcicanadainvoice/InvoiceEditForm'
import InvoiceDetailsAndSummaryInvoiceSection from './InvoiceDetailsAndSummaryInvoiceSection'
import ViewLogsAndHistorySection from './ViewLogsAndHistorySection'
import AlertDialog from '../../components/aciacehighway/AlertDialog'
import { FileForUserGetUserDetails } from 'src/common/FileForUserCommon'

function EditPage(props) {
  const { history, location, onSetLoading, showNotification } = props
  const { id } = useParams()
  const { state } = location
  const [step, setStep] = useState(state?.step || 0)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const invoiceData = useRef(state?.invoiceData)
  const [submitAlertDialog, setSubmitAlertDialog] = useState(false)
  const { securedSendRequest } = useServices()

  const isPendingSave = useRef(false)
  const isPendingSaveShipmentSummary = useRef(false)

  function handleSubmit() {}

  const memoedInvoiceDetailsAndSummaryInvoiceSection = useMemo(() => (
    <InvoiceDetailsAndSummaryInvoiceSection
      history={history}
      invoiceData={invoiceData}
      onBack={() => setStep((prev) => prev - 1)}
      onDiscard={() => setConfirmDialog(true)}
      // onSetInvoiceData={setInvoiceData}
      onSetLoading={onSetLoading}
      onSkip={() => setStep((prev) => prev + 1)}
      onSubmit={preSubmit}
      showNotification={showNotification}
      isPendingSave={isPendingSave}
      isPendingSaveShipmentSummary={isPendingSaveShipmentSummary}
    />
  ), [invoiceData.current])

  function renderContent(step) {
    switch (step) {
      case 0:
        return (
          <InvoiceEditForm
            history={history}
            id={id}
            invoiceData={invoiceData}
            onContinueNextStep={() => setStep((prev) => prev + 1)}
            onDiscard={() => setConfirmDialog(true)}
            // onSetInvoiceData={setInvoiceData}
            showNotification={showNotification}
          />
        )
      case 1:
        return (
          memoedInvoiceDetailsAndSummaryInvoiceSection
        )
      case 2:
        return (
          <ViewLogsAndHistorySection
            id={id}
            onBack={() => setStep((prev) => prev - 1)}
            onDiscard={() => setConfirmDialog(true)}
            onSubmit={preSubmit}
          />
        )
    }
  }

  function preSubmit() {
    if (isPendingSave.current || isPendingSaveShipmentSummary.current) {
      setSubmitAlertDialog(true)
    } else {
      handleSubmit()
    }
  }

  function handleSubmit() {
    setSubmitAlertDialog(false)
    onSetLoading(true)
    let invData = invoiceData.current
    let fileForUserDetails = FileForUserGetUserDetails();
    if (fileForUserDetails != null && fileForUserDetails != undefined) {
      invData.fileForUserId = fileForUserDetails.fileForUserId;
      invData.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
      invData.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
    }

    securedSendRequest.execute(
      'POST',
      NBCICanadaInvoiceApiUrls.SUBMIT,
      invData,
      (response) => {
        var data = response.data
        var errorMessages = data.errorMessages

        if (response.status == 200 && data != null && errorMessages == null) {
          showNotification('success', 'Submit successfully')
          history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)
        } else {
          showNotification('error', errorMessages)
        }
      },
      (error) => console.error(error),
      () => onSetLoading(false)
    )
  }

  return (
    <>
      <Card>
        <Stepper
          steps={[
            'Provide details',
            'Add invoice lines & summary invoice',
            'View logs & history'
          ]}
          activeStep={step}
          onSetActiveStep={(step) => setStep(step)}
        />
        <Box padding={2}>
          {renderContent(step)}
        </Box>
      </Card>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, discard'
        open={confirmDialog}
        onClose={() => setConfirmDialog(false)}
        onCancel={() => setConfirmDialog(false)}
        onConfirm={() => history.push(pathMap.N_B_C_I_CANADA_INVOICE_LIST_VIEW)}
        title='Discard'
      >
        All progress in this session will be lost and cant't be restored. Are
        you sure about this?
      </AlertDialog>
      <AlertDialog
        cancelLabel='Continue editing'
        confirmLabel='Yes, submit'
        open={submitAlertDialog}
        onClose={() => setSubmitAlertDialog(false)}
        onCancel={() => setSubmitAlertDialog(false)}
        onConfirm={() => handleSubmit()}
        title='Alert'
      >
        Changes have not been saved. Do you want to proceed without saving?
      </AlertDialog>
    </>
  )
}

export default withFormState(EditPage)
